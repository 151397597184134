import React, { FC, useEffect } from 'react'
import { CurrentUserType } from 'src/ducks/auth'
import { LobbyEntry } from '../../components/LobbyEntry'
import PageWithBg from '../../components/PageWithBg'
import config from '../../config'
import Amplitude from '../../utils/amplitudeHelper'
import { StyledLobby } from './styled'
import { desktopAssets } from 'src/containers/PreCache'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router'

export const LobbyPage: FC<{ currentUser: CurrentUserType }> = ({ currentUser }) => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const toastMsg = query.get('message');

  useEffect(() => {
    window.chaport.q('hideLauncher')
    document.title = `${config.eventName} | Lobby`
  }, [])

  useEffect(() => {
    Amplitude.visit('Lobby')
  }, [currentUser.id])

  useEffect(() => {
    toast(toastMsg, { toastId: 'auditoriumClosed'})
  }, [toastMsg])

  return (
    <>
      <StyledLobby>
        <PageWithBg bgImg={desktopAssets.lobbyBg}>
          {config.lobbyEntries.map((entry) => {
            return <LobbyEntry key={entry.id} {...entry} currentUser={currentUser} />
          })}
        </PageWithBg>
      </StyledLobby>
    </>
  )
}