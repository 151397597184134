import routes from './routes'
import { LobbyEntryProps, LobbyEntryTypes } from './components/LobbyEntry/LobbyEntry'

// audio
import LobbyMusic from './images/audio/lobby.mp3'

// icons & decorations
import { desktopAssets, fixedAssets } from './containers/PreCache'
import { GlobalModalTypes } from './components/GlobalModals/GlobalModalContainer'

const dev = {
  apiUrl: 'http://localhost:3000',
}

const prod = {
  apiUrl: 'https://server.imfedgrowthsummit2024.com',
}

const EVENT_URL = process.env.NODE_ENV === 'production' ? 'https://imfedgrowthsummit2024.com/' : 'http://localhost:3001'

const API_KEYS = {
  amplitude: '8c992eb634a2ef42ed167eb55b3c48d8',
}

const VOLUME_VALUE = 0.4

// Root page will show either EntrancePage or CountdownPage
// based on the following time setting
const EVENT_START_TIME = {
  year: 2024,
  month: 8,
  day: 18,
  hour: 9,
  minute: 30,
}

const config = process.env.NODE_ENV === 'production' ? prod : dev

const assets = {
  lobbyMusic: LobbyMusic,
}

const layout = {
  auditoriumVid: `
    top: 10%;
    left: 5%;
    right: 32%;
    bottom: 20%;
  `,
}

const lobbyEntries: Array<LobbyEntryProps & { id: string }> = [
  {
    id: 'helpdesk',
    type: LobbyEntryTypes.Helpdesk,
    // type: LobbyEntryTypes.GlobalModal,
    // modalId: GlobalModalTypes.ComingSoon,
    pinImage: fixedAssets.infoIcon,
    css: `
      top: 77%;
      left: 7%;
      width: 4%;
      height: 8%;
    `,    
  },
  {
    id: 'auditorium-entrance',
    type: LobbyEntryTypes.DirectRoute,
    destination: routes.replay,
    pinImage: desktopAssets.arrow,
    css: `
      top: 31%;
      left: 46.5%;
      width: 5%;
      height: 10%;
    `,
  },
  {
    id: 'booth-1',
    type: LobbyEntryTypes.DirectRoute,
    destination: routes.booth1,
    pinImage: desktopAssets.arrow,
    css: `
      top: 80%;
      left: 44.4%;
      width: 9%;
      height: 17%;
    `,
  },
  {
    id: 'booth-large',
    type: LobbyEntryTypes.DirectRoute,
    destination: routes.booth1,
    // pinImage: desktopAssets.arrow,
    css: `
      top: 48%;
      left: 31%;
      width: 36%;
      height: 38%;
    `,
  },
  // {
  //   id: 'gallery-entrance',
  //   type: LobbyEntryTypes.DirectRoute,
  //   destination: routes.onDemand,
  //   pinImage: fixedAssets.ondemandEntrance,
  //   css: `
  //     top: 55.2%;
  //     left: 48.2%;
  //     right: 38.8%;
  //     bottom: 15.5%;
  //   `,
  //   pinText: "VIDEO ON-DEMAND",
  //   textCss: `
  //     top: 30%;
  //     left: 12.5%;
  //     right: 12.5%;
  //     bottom: 57%;
  //     transform: rotateX(0deg) rotateY(15deg);
  //   `,
  // },
  // {
  //   id: 'exhibition-entrance',
  //   type: LobbyEntryTypes.GlobalModal,
  //   modalId: GlobalModalTypes.EntrySelection,
  //   pinImage: fixedAssets.exhibitionEntrance,
  //   css: `
  //     top: 45%;
  //     left: 63%;
  //     right: 22%;
  //     bottom: 16%;
  //   `,
  //   pinText: "EXHIBITION HALL",
  //   textCss: `
  //     top: 30%;
  //     left: 7%;
  //     right: 22%;
  //     bottom: 42%;
  //     transform: rotateX(0deg) rotateY(-15deg);
  //   `,
  // },
]

export default {
  API_KEYS,
  assets,
  layout,
  lobbyEntries,
  VOLUME_VALUE,
  EVENT_START_TIME,
  EVENT_URL,

  eventName: 'IMFeD Growth Summit 2024',

  ...config,
}
