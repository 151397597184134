import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { DocumentViewer } from 'src/components/GlobalModals/DocumentViewer.js'
import IframeModal from 'src/components/GlobalModals/IframeModal.js'
import { fixedAssets, mobileAssets } from 'src/containers/PreCache.js'
import styled from 'styled-components'
import { getBooth, getBooths } from '../ducks/booth'
import Amplitude from '../utils/amplitudeHelper'
import pdf from '../images/booth/pdf-icon.png'
import video from '../images/booth/video-icon.png'
import info from '../images/booth/info-icon.png'
import { Loader } from 'src/components/shared/Loader'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, Zoom, EffectCoverflow } from 'swiper'
import { viewBooth } from './BoothPage'
import NoticeBar from 'src/components/NoticeBar'

const MobileBooth = ({ currentUser }) => {
  const history = useHistory()
  const { boothId } = useParams()
  const dispatch = useDispatch()
  // const { viewBooth, booths } = useSelector((state) => state.booth)

  const [iframeOpen, setIframeOpen] = useState(false)
  const [playVideo, setPlayVideo] = useState({})
  const [documentSelected, setDocumentSelected] = useState(null)
  const [selectedTab, setSelectedTab] = useState('')
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   dispatch(getBooth(boothId))
  // }, [boothId, dispatch])

  useEffect(() => {
    if (viewBooth) {
      // dispatch(getBooths(viewBooth.exhibition_hall.id))
      Amplitude.visitBooth(viewBooth.title)
      setSelectedTab(viewBooth?.content ? 'info' : '')
    }
  }, [currentUser.id, viewBooth])

  const handleModal = (video) => {
    Amplitude.watchBoothVideo(video.filename)
    setPlayVideo(video)
    setIframeOpen(true)
  }

  const handleDownload = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'pdf')
    setSelectedTab('pdf')
  }

  const handleVideoModal = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'video')
    Amplitude.watchBoothVideo(viewBooth.boothVideos[0])
    setSelectedTab('video')
  }
  // const handlePoster = () => {
  //   Amplitude.clickHotButtons(viewBooth.title, 'wallpaper')
  //   setSelectedTab('poster')
  // }
  const handleInfo = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'info')
    setSelectedTab('info')
  }

  const handleChat = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'chat')
    window.location.href = 'mailto:secretariat@imfed.my';
  }

  // const goNext = () => {
  //   const navigateTo = booths.filter(booth=>booth.order===viewBooth.order+1)[0].id
  //   const nextLink = `/booths/${navigateTo}`
  //   history.push(nextLink)
  // }

  // const goPrevious = () => {
  //   const navigateTo = booths.filter(booth=>booth.order===viewBooth.order-1)[0].id
  //   const prevLink = `/booths/${navigateTo}`
  //   history.push(prevLink)
  // }

  if (!viewBooth) {
    return (
      <Page>
        <Loader fullPage loading />
      </Page>
    )
  }
  return (
    <Page>
      {/* <NoticeBar /> */}
      <div className="outer-wrapper">
        {/* <div className="navCon pt-1 pb-2">
          <div className="container w-100">
            <div className="nav-wrapper">
              <p className="booth-name">{viewBooth.title}</p>
              <div className="nav-buttons-wrapper">
                {viewBooth.order !== 0 && (
                  <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839" className="nav-button-icon" onClick={goPrevious}>
                    <g transform="translate(17.301 26.839) rotate(180)">
                      <path d="M.459,14.462,13.147,26.407a1.637,1.637,0,0,0,2.216,0l1.48-1.393a1.415,1.415,0,0,0,0-2.084L6.79,13.419,16.845,3.909a1.415,1.415,0,0,0,0-2.084L15.363.432a1.637,1.637,0,0,0-2.216,0L.459,12.376A1.415,1.415,0,0,0,.459,14.462Z" transform="translate(17.301 26.839) rotate(180)" fill="#000"/>
                    </g>
                  </svg>
                )}
                {viewBooth.order !== booths.length-1 && (
                  <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839" className="nav-button-icon" onClick={goNext}>
                    <path d="M.459,12.376,13.147.432a1.637,1.637,0,0,1,2.216,0l1.48,1.393a1.415,1.415,0,0,1,0,2.084L6.79,13.419,16.845,22.93a1.415,1.415,0,0,1,0,2.084l-1.48,1.393a1.637,1.637,0,0,1-2.216,0L.459,14.462A1.415,1.415,0,0,1,.459,12.376Z" transform="translate(17.301 26.839) rotate(180)" fill="#000"/>
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div> */}

        <div className="booth-image-wrapper">
          <img src={mobileAssets.organizerLogoMobile} className="booth-org" alt="organisation logo" />
          <img src={mobileAssets.mobileBoothBg} className="booth-image" alt="booth" />
        </div>

        <div className="booth-material-container">
          {viewBooth ? (
            <div className="booth-menu-list">
              <div className={`${selectedTab === 'info' && 'active'} booth-menu-item`} onClick={handleInfo}>
                <img src={mobileAssets.boothInfoIcon} alt="info-icon" className="booth-menu-item-icon" />
              </div>
              <div className={`${selectedTab === 'video' && 'active'} booth-menu-item`} onClick={handleVideoModal}>
                <img src={mobileAssets.boothPlayIcon} alt="play-icon" className="booth-menu-item-icon" />
              </div>
              <div className={`${selectedTab === 'pdf' && 'active'} booth-menu-item`} onClick={handleDownload}>
                <img src={mobileAssets.boothPdfIcon} alt="pdf-icon" className="booth-menu-item-icon" />
              </div>
              {/* <div className={`${selectedTab === "poster" && 'active'} booth-menu-item`} onClick={handlePoster}>
                  <img src={wallpaper} className="booth-menu-item-icon" alt="resource-icon"/>
                </div> */}

              <div className={`booth-menu-item`} onClick={handleChat}>
                <img src={mobileAssets.boothChatIcon} alt="chat-icon" className="booth-menu-item-icon" />
              </div>
            </div>
          ) : (
            <div className="load-container">
              <Loader />
            </div>
          )}
        </div>
        {selectedTab === 'info' && (
          <div className="info-tab tabs">
            <h5 className="booth-title">{viewBooth?.title}</h5>
            <div className="content" dangerouslySetInnerHTML={{ __html: viewBooth?.content }} />
          </div>
        )}
        {selectedTab === 'video' && (
          <div className="video-tab tabs">
            <div className="iframe-container">
              {loading && <Loader fullPage={false} loading />}
              <iframe
                width="100%"
                height="100%"
                title={viewBooth.boothVideos[0].filename}
                src={`${viewBooth.boothVideos[0].url}?autoplay=1`}
                allow="autoplay; fullscreen; encrypted-media"
                allowFullScreen
                allowtransparency="true"
                frameBorder="0"
                onLoad={() => {
                  setLoading(false)
                }}
              />
            </div>
            {/* <h5 className="text-center">Select video(s) to play</h5> */}
            {/* {viewBooth.boothVideos.map((video, index) => (
              <div key={`video-${index}`} className="file-container" onClick={() => handleModal(video)}>
                <p>{video.fileName}</p>
                <button>
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <polygon points="10 8 16 12 10 16 10 8"></polygon>
                  </svg>
                </button>
              </div>
            ))} */}
          </div>
        )}
        {selectedTab === 'pdf' && (
          <div className="pdf-tab tabs">
            <h5 className="text-center">Select file(s) to preview</h5>
            {viewBooth.documents.map((file) => (
              <div key={file.fileName} className="file-container" onClick={() => setDocumentSelected(file)}>
                <p>{file.fileName}</p>
                <button>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="currentColor" d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline stroke="currentColor" points="7 10 12 15 17 10"></polyline>
                    <line stroke="currentColor" x1="12" y1="15" x2="12" y2="3"></line>
                  </svg>
                </button>
              </div>
            ))}
          </div>
        )}
        {/* {selectedTab === 'poster' && (
          <div className="poster-tab tabs">
            {viewBooth.wallpaper_gallery.length > 0 && (
              <Swiper
                className="mySwiper"
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, Zoom, EffectCoverflow]}
                slidesPerView={1}
                navigation={true}
                effect="coverflow"
                zoom={true}
                pagination={{
                  clickable: true,
                  renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>'
                  },
                }}
                scrollbar={{ draggable: true }}
              >
                {viewBooth.wallpaper_gallery.map((poster, index) => (
                  <SwiperSlide key={`poster-${index}`} zoom={true}>
                    <img className="d-block" src={poster.url} alt={`poster-${index}`} width="100%" />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        )} */}

        {iframeOpen && <IframeModal iframeOpen={iframeOpen} setIframeOpen={setIframeOpen} iframe={playVideo} />}
        {documentSelected && <DocumentViewer file={documentSelected} setDocumentSelected={setDocumentSelected} />}
      </div>
    </Page>
  )
}

const Page = styled.div`
  height: calc(100% - 50px);
  width: 100vw;
  overflow-y: scroll;
  position: absolute;
  color: #000;

  .container {
    width: 90%;
  }
  .nav-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .booth-name {
    font-size: 14px;
    margin: 0;
  }
  .tabs {
    width: 90%;
    padding: 4px;
    margin: 4px auto;
    color: #000;
    opacity: 0;
    animation: appear 500ms 500ms forwards;

    h5 {
      font-size: 16px;
    }
    .content {
      font-size: 14px;
    }
  }
  .file-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;

    p {
      font-size: 14px;
      margin: 0;
    }
    button {
      background: transparent;
      border: none;
      color: #ffad2c;
    }
  }
  .booth-title {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .booth-image-wrapper {
    background: var(--primary);
    /* background-image: url(${fixedAssets.boothBg});
    background-size: cover;
    background-position: center; */
    width: 100%;
    position: relative;
  }
  .booth-org {
    position: absolute;
    width: 90%;
    top: 3%;
    left: 50%;
    z-index: 1;
    border-radius: 10px;
    transform: translate(-50%);
  }

  .booth-image {
    width: 100%;
    opacity: 0;
    animation: appear 500ms 500ms forwards;
  }

  .booth-material-container {
    position: relative;
    margin-bottom: 2%;
  }
  .booth-menu-list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 0;
    padding: 8px 0;
    position: relative;
  }
  .booth-menu-item {
    width: 18%;
    padding: 8px 0;
    border-bottom: 3px solid transparent;
    transition: all 300ms ease-in;
  }
  .active {
    border-bottom: 3px solid var(--secondary);
  }
  .booth-menu-item-icon {
    width: 40%;
    max-width: 64px;
    margin: auto;
    display: block;
    text-align: center;
  }
  .nav-buttons-wrapper {
    width: 50px;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav-button-icon {
    height: 20px;
  }

  .outer-wrapper {
    padding-bottom: 3%;
  }
  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
      padding-bottom: 3%;
    }

    .booth-image-wrapper {
      height: 300px;
    }
  }
`

export default MobileBooth
