import React, { useState } from 'react'
import { Button, Col, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { get } from 'src/apis/apiService'
import { AUTH_APIS } from 'src/apis/authApi'
import { ForgetPasswordModal, StyledWrapper } from './styled'

export const ForgetPassword = () => {
  const [show, setShow] = useState(false)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = () => setShow(false)

  const handleClick = (e) => {
    e.preventDefault()
    setShow(true)
  }

  const handleInput = (e) => setEmail(e.target.value.toLowerCase())

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    get(
      AUTH_APIS.forgetPassword,
      (data) => {
        setLoading(false)
        if (data.exist) {
          toast('We have sent you the access code via email, please check your mailbox.')
          setShow(false)
          setEmail('')
        } else {
          toast('This email does not seem to be registered to our system.')
        }
      },
      (error) => {
        setLoading(false)
        toast(error.response?.data?.message)
      },
      { email }
    )
  }

  return (
    <>
      <StyledWrapper>
        <div id="btn" className='my-2' onClick={handleClick}>
          <small>Forgot Password?&nbsp; <span className="link">Click here</span></small>
        </div>
      </StyledWrapper>
      <ForgetPasswordModal show={show} onHide={handleClose} size="md" aria-labelledby="schedule-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Enter Email to request for Password</Form.Label>
              <Form.Control type="email" placeholder="Enter email here..." onChange={handleInput} value={email} />
            </Form.Group>
          </Form.Row>
          <Button
            id="verify-btn"
            disabled={email.length < 4 || loading}
            variant="warning"
            style={{ background: '#8468ce', color: '#fff' }}
            onClick={handleSubmit}
          >
            {loading ? 'Verifying...' : 'Verify Email'}
          </Button>
        </Modal.Body>
      </ForgetPasswordModal>
    </>
  )
}
