import styled from 'styled-components'

interface LoaderStyleProps {
  fullPage?: boolean
}

export const LoadIcon = styled.div<LoaderStyleProps>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;

  .preloader {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #fff;
    background: ${(props) => (props.fullPage ? 'var(--primary)' : 'transparent')};
    transition: 0.3s all ease;
  }

  .cssload-container {
    width: 100%;
    height: 36px;
    text-align: center;
  }

  .cssload-speeding-wheel {
    width: 36px;
    height: 36px;
    margin: 0 auto;
    border: 3px solid #fff;
    border-radius: 50%;
    border-left-color: transparent;
    border-bottom-color: transparent;
    animation: cssload-spin 0.88s infinite linear;
  }

  .preloader-body p {
    position: relative;
    margin-top: 8px;
    font-size: 14px;
  }

  @keyframes cssload-spin {
    100% {
      transform: rotate(360deg);
    }
  }
`
