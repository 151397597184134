import { useEffect } from 'react'
import { useObject } from 'react-firebase-hooks/database'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AuditoriumClosedRef } from 'src/firebaseConfig'
import routes from 'src/routes'

export const useAuditoriumController = () => {
  const [snapshot, loading, error] = useObject(AuditoriumClosedRef)
  const history = useHistory()
  const currentUser = useSelector((state) => state.auth.currentUser)

  useEffect(() => {
    if (currentUser.role !== 'superadmin' && !loading && snapshot && snapshot.val() === '0') {
      history.replace(routes.lobby + '?message=Auditorium is closed')
    }
  }, [snapshot])

  if (loading) {
    return null
  }

  return !!snapshot
}
