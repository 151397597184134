import React, { useEffect } from 'react'
import styled from 'styled-components'
// import closeTheatre from '../../images/mobile/close-theatre.png'
// import openTheatre from '../../images/mobile/open-theatre.png'
import Amplitude from '../../utils/amplitudeHelper'
import { useAutoMute } from '../../utils/useAutoMute'
import { fixedAssets, mobileAssets } from 'src/containers/PreCache'
import config from 'src/config'
import MobileHeader from 'src/components/MobileHeader'
import { useDispatch } from 'react-redux'
import { showGlobalModal } from 'src/ducks/layout'
import { GlobalModalTypes } from 'src/components/GlobalModals/GlobalModalContainer'
import { SIZES } from 'src/styles/theme'
import NoticeBar from 'src/components/NoticeBar'
import { useAuditoriumController } from 'src/utils/useAuditoriumController'
import routes from 'src/routes'
import { useHistory } from 'react-router'

const MobileAuditorium = ({ currentUser }) => {
  useAutoMute()
  useAuditoriumController(currentUser)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    window.chaport.q('hideLauncher')
    document.title = `${config.eventName} | Auditorium`
    Amplitude.visit(`Auditorium - Replay`)
  }, [])

  return (
    <Page>
      <NoticeBar />
      <div className="outer-wrapper">
        <div className="container">
        <img src={mobileAssets.organizerLogoMobile} className="booth-org w-100" alt="organisation logo" />
          <div className="screen-container">
            <div className="video-container">
            <iframe title="Replay" src="https://vimeo.com/showcase/11327434/embed?autoplay=1&loop=1&autopause=0&muted=1" allowFullscreen frameBorder="0" />
            </div>
          </div>

          {/* <button type="button" className="theatre-button" onClick={() => setTheatre(true)}>
            <img src={openTheatre} className="theatre-button-icon" alt="theatre-icon" />
            <p className="theatre-button-label">Theatre mode</p>
          </button> */}
        </div>

        <div className="controls d-flex flex-row">
          <Btn onClick={() => dispatch(showGlobalModal(GlobalModalTypes.Agenda))}>Agenda</Btn>
          <Btn onClick={() => dispatch(showGlobalModal(GlobalModalTypes.Speakers))}>Speakers</Btn>
        </div>

        <div className='text-center'>
          <img src={mobileAssets.mobileAuditoriumKv} alt="Girl" className="w-100"/>
        </div>

        {/* <div className="chat-container">
          <iframe 
            src="https://app.sli.do/event/3JfMC7uYMJbDJ4do9cFcCA" 
            height="100%" 
            width="100%" 
            frameBorder="0" 
            style={{minHeight: '560px'}} 
            title="slido"
          ></iframe>
        </div> */}
      </div>
    </Page>
  )
}
const Btn = styled.div`
  border-radius: 4rem;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  font-weight: bold;
  padding: 9px 0;
  /* background: linear-gradient(118deg, #323a9b -2.53%, #32b24a 107.38%); */
  background: var(--secondary);
  cursor: pointer;
  width: fit-content;
  border: 0px;
  font-size: 1.2rem;
  transition: all 200ms ease-in-out;
  max-width: 115px;
  &:hover {
    transform: scale(1.1);
  }

  ${SIZES.mobile} {
    width: 100%;
    font-size: 8px 41px;
  }
`

const Page = styled.div`
  /* background-color: #f8c605;
  background-repeat: no-repeat;
  background-position: bottom center;
  @media (max-width: 1200px), (min-height: 1000px) {
    background-size: cover;
  }
  @media (min-width: 1201px) and (max-width: 2400px), screen and (min-height: 1000px) {
    background-size: 200%;
  }
  background-image: url(${mobileAssets.mobileAuditoriumBg});
  background-size: cover; */
  background: var(--primary);

  height: calc(100% - 50px);
  width: 100vw;
  overflow-y: scroll;
  position: absolute;

  .outer-wrapper {
    width: 100%;
    margin-top: 40px;
  }

  .container {
    width: 98%;
    margin: auto;
  }

  .booth-org {
    width: 90%;
    border-radius: 10px;
  }

  .screen-container {
    width: 100%;
    height: 0;
    padding-top: 58.5%;
    position: relative;
  }

  .video-container {
    width: 98%;
    height: 81%;
    background: #1e1e1e;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50.5%, -51%);
    display: flex;
    justify-content: center;
    align-items: center;

    iframe,
    video {
      height: 100%;
      width: 100%;
    }

    .zoom {
      text-align: center;
      img {
        max-width: 50%;
      }
    }
  }

  .button-container {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    div {
      text-transform: uppercase;
      background: #000000;
      padding: 4px 1rem;
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      border: 2px solid #fff;

      &:hover {
        color: #fff;
        transform: scale(1.1);
      }
    }
  }

  .chat-container {
    width: 100%;
    height: 600px;
    margin-top: 12px;
  }

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    gap: 2rem;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileAuditorium
