import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
// import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyCXms7xec2a76Gm_mLlQzu6DXQ8q2xB9rA",
  authDomain: "growthsummit24.firebaseapp.com",
  databaseURL: "https://growthsummit24-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "growthsummit24",
  storageBucket: "growthsummit24.appspot.com",
  messagingSenderId: "821575014704",
  appId: "1:821575014704:web:9d0a9974c43c16b79d90f5"
};


firebase.initializeApp(firebaseConfig)
// firebase.analytics()

const firebaseDb = firebase.database()
const firebaseAuth = firebase.auth()

const getDbRef = (dbRef) => {
  const envDbRef = `${process.env.NODE_ENV === 'production' ? 'production' : 'local'}/${dbRef}`
  return firebaseDb.ref(envDbRef)
}

const ChatDb = getDbRef('chat')
const HallOneDb = getDbRef('Hall_A_Question')
const HallTwoDb = getDbRef('Hall_B_Question')
const HallOneChatDb = getDbRef('Hall_A_Chat')
const HallTwoChatDb = getDbRef('Hall_B_Chat')
const NoticeDb = getDbRef('notice')
const NotificationDb = getDbRef('pushNotifications')
const PopupRef = getDbRef('LaunchPopup')
const VideoRef = getDbRef('GrowthCentre')
const AuditoriumClosedRef = getDbRef('auditoriumAccess')

const getUniqueSessionDb = (uid) => getDbRef(`uniqueSession/${uid}`)
const getUserCountDb = (uid, path) => getDbRef(`userCount/${path}/${uid}`)
const getUserSessionDb = (uid) => getDbRef(`userSessions/${uid}`)
const getOnlineStatusDb = (uid) => getDbRef(`onlineStatus/${uid}`)
const getLiveCheckInSessionDb = (hallNumber) => getDbRef(`liveCheckInSession/hall-${hallNumber}`)
const getRefreshDb = (uid) => getDbRef(`shouldRefresh/${uid}`)
const getLiveSessionDb = (hallNumber) => getDbRef(`liveSession/${hallNumber}`)

export {
  firebaseDb,
  firebaseAuth,
  ChatDb,
  HallOneDb,
  HallTwoDb,
  HallOneChatDb,
  HallTwoChatDb,
  NoticeDb,
  NotificationDb,
  PopupRef,
  VideoRef,
  AuditoriumClosedRef,
  getUniqueSessionDb,
  getUserCountDb,
  getUserSessionDb,
  getOnlineStatusDb,
  getLiveCheckInSessionDb,
  getRefreshDb,
  getLiveSessionDb
}
