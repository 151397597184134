import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Amplitude from '../../utils/amplitudeHelper'
import routes from '../../routes'
import config from '../../config'
import { showGlobalModal } from 'src/ducks/layout'
import { GlobalModalTypes } from 'src/components/GlobalModals/GlobalModalContainer'
import { mobileAssets } from 'src/containers/PreCache'
import NoticeBar from 'src/components/NoticeBar'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router'

const MobileLobby = ({ currentUser }) => {
  const scrollRef = useRef(null)
  const dispatch = useDispatch()
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const toastMsg = query.get('message');

  useEffect(() => {
    document.title = `${config.eventName} | Lobby`
    Amplitude.visit('Lobby')
  }, [currentUser.id])

  useEffect(() => {
    toast(toastMsg, { toastId: 'auditoriumClosed'})
  }, [toastMsg])

  const showHelpDesk = () => {
    const cpt = window.chaport
    cpt.on('ready', function () {
      cpt.setVisitorData({
        name: currentUser.fullName,
        email: currentUser.email,
      })
      cpt.open()
    })
    cpt.on('collapsed', function () {
      cpt.close()
    })
    Amplitude.clickHelpDesk()
  }

  const handleScrollRight = () => {
    scrollRef.current.scrollBy({
      left: 400,
      behavior: 'smooth',
    })
  }

  return (
    <Page>
      <NoticeBar />
      <div className="outer-wrapper">
        <div className="lobby-image-wrapper"></div>
        <hr className='divider'/>
        <div className="menu">
          <p className="title">Virtual Halls</p>
          <div className="btn-container">
            <div id="moreBtn" onClick={handleScrollRight}>
              <em>More</em>
              <svg width="23.81" height="6.985" viewBox="0 0 23.81 6.985">
                <g transform="translate(0.75 1.06)">
                  <path
                    d="M677.491,759h21.685"
                    transform="translate(-677.491 -754)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M711.207,746.126l5.294,4.866"
                    transform="translate(-694.501 -746.126)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                </g>
              </svg>
            </div>
          </div>

          <div className="list-wrapper" ref={scrollRef}>
            <div className="item-box">
              <div className="icon-wrapper">
                <img className="menu-icon" src={mobileAssets.auditoriumIcon} alt="icon" />
              </div>
              <p className="item-label">Auditorium</p>
              <Link to={routes.replay} className="item-button">
                Enter
              </Link>
            </div>

            {/* <div className="item-box">
              <div className="icon-wrapper">
                <img className="menu-icon" src={gallery} alt="icon" />
              </div>
              <p className="item-label">Video On-Demand</p>
              <Link to={routes.onDemand} className="item-button">
                Enter
              </Link>
            </div> */}

            <div className="item-box">
              <div className="icon-wrapper">
                <img className="menu-icon" src={mobileAssets.boothIcon} alt="icon" />
              </div>
              <p className="item-label">Exhibition</p>
              <Link to={routes.booth1} className="item-button">
                Enter
              </Link>
            </div>

            <div className="item-box">
              <div className="icon-wrapper">
                <img className="menu-icon" src={mobileAssets.agendaIcon} alt="icon" />
              </div>
              <p className="item-label">Agenda</p>
              <div onClick={() => dispatch(showGlobalModal(GlobalModalTypes.Agenda))} className="item-button">
                Enter
              </div>
            </div>

            <div className="item-box">
              <div className="icon-wrapper">
                <img className="menu-icon" src={mobileAssets.speakerIcon} alt="icon" />
              </div>
              <p className="item-label">Speakers</p>
              <div onClick={() => dispatch(showGlobalModal(GlobalModalTypes.Speakers))} className="item-button">
                Enter
              </div>
            </div>

            <div className="item-box">
              <div className="icon-wrapper">
                <img className="menu-icon" src={mobileAssets.helpdeskIcon} alt="icon" />
              </div>
              <p className="item-label">Help Desk</p>
              <div onClick={showHelpDesk} className="item-button">
                Enter
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: #fff;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 100%;

  height: calc(100% - 50px);
  width: 100vw;
  overflow-y: scroll;
  position: absolute;

  .menu-icon {
    width: 100%;
  }
  .outer-wrapper {
    height: 100dvh;
    background-color: var(--primary);
  }
  .lobby-image-wrapper {
    background-image: url(${mobileAssets.mobileLobbyBg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: calc(100vh - 350px);
    min-height: 300px;
    padding: 1rem;
    overflow: hidden;
  }
  .menu {
    width: 100%;
    height: 240px;
    position: relative;
    color: #fff;
  }
  hr {
    border-color: #d7b367;
    border-width: 1rem;
    margin:0;
  }
  .title {
    font-size: 15px;
    font-weight: bold;
    font-style: italic;
    position: absolute;
    top: 10px;
    left: 15px;
  }
  .btn-container {
    display: flex;
    position: absolute;
    top: 12px;
    right: 20px;
  }
  #moreBtn {
    width: 80px;
    height: 22px;
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: flex-end;
    em {
      font-size: 14px;
      margin-right: 8px;
    }
  }
  .list-wrapper {
    width: 100%;
    height: 160px;
    position: absolute;
    top: 45px;
    left: 0;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .item-box {
    width: 130px;
    height: 160px;
    margin-left: 20px;
    border: solid 3px #d7b367;
    border-radius: 10px;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .icon-wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item-label {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    margin: 0px;
  }
  .item-button {
    background: var(--gold);
    color: #000;
    border: none;
    border-radius: 30px;
    font-size: 12px;
    font-weight: bold;
    width: 90px;
    height: 25px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: 540px) {
    .menu {
      height: 250px;
    }
    .list-wrapper {
      top: 65px;
    }
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileLobby
