import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect, useHistory } from 'react-router-dom'
import config from '../../config'
import { loginUser } from '../../ducks/auth'
import routes from '../../routes'
import { ForgetPassword } from './ForgetPassword'
import styled from 'styled-components'
import { COLORS, SIZES } from 'src/styles/theme'
import { desktopAssets, fixedAssets, mobileAssets } from 'src/containers/PreCache'
import Media from 'react-media'

export const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { isFetching, isAuthenticated } = useSelector((state) => state.auth)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleLogin = (e) => {
    e.preventDefault()
    loginUser(dispatch, history, {
      email,
      password,
    })
  }
  const onPasswordInput = (e) => {
    setPassword(e.target.value)
  }

  const onEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  useEffect(() => {
    document.title = `${config.eventName} | Login`
    window.chaport.q('showLauncher')
  }, [])

  // const showHelpDesk = () => {
  //   const cpt = window.chaport
  //   cpt.on('ready', function () {
  //     cpt.open()
  //   })
  //   cpt.on('collapsed', function () {
  //     cpt.close()
  //   })
  // }

  if (isAuthenticated) {
    return <Redirect to={routes.lobby} />
  } else {
    return (
      <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
        {(matches) =>
          matches ? (
            <MobileStyle>
              <img src={mobileAssets.mobileLoginBg} alt="Login Background" className="w-100" />
              <div className="login-wrapper">
                <div className="title">Login</div>
                <form onSubmit={handleLogin}>
                  <input type="email" placeholder="Email Address" value={email} onChange={onEmailInput} />
                  <input type="password" value={password} placeholder="Password" onChange={onPasswordInput} />

                  <ForgetPassword />
                  <p className="regLink">
                    New Delegate? <Link to={routes.register}>Register here</Link>
                  </p>

                  <div className="btn login-btn" onClick={handleLogin} style={{ background: isFetching ? 'lightgray' : 'linear-gradient(#F7DA74, #C88131)' }}>
                    <input disabled={!(email.length && password.length) || isFetching} type="submit" value={isFetching ? '' : ''} />
                    <div>
                      <img src={fixedAssets.whiteRightArrow} className="white-arrow" alt="icon" />
                    </div>
                  </div>
                </form>
              </div>
            </MobileStyle>
          ) : (
            <LoginPageStyle>
              <form onSubmit={handleLogin}>
                <p className="title">Login</p>
                <input type="email" placeholder="Email Address" value={email} onChange={onEmailInput} />
                <input type="password" value={password} placeholder="Password" onChange={onPasswordInput} />

                <ForgetPassword />
                <p className="regLink">
                  New Delegate? <Link to={routes.register}>Register here</Link>
                </p>

                <div className="btn login-btn" onClick={handleLogin} style={{ background: isFetching ? 'lightgray' : 'linear-gradient(#F7DA74, #C88131)' }}>
                  <input disabled={!(email.length && password.length) || isFetching} type="submit" value={isFetching ? '' : ''} />
                  <div>
                    <img src={fixedAssets.whiteRightArrow} className="white-arrow" alt="icon" />
                  </div>
                </div>
              </form>
            </LoginPageStyle>
          )
        }
      </Media>
    )
  }
}

export const LoginPageStyle = styled.section`
  position: fixed;
  max-height: 100%;
  text-align: center;
  color: white;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background: url(${desktopAssets.loginBg});
  background-size: cover;
  background-position: center;

  .action-text {
    cursor: pointer;
  }

  .mobile-show {
    display: none;
    height: 50vh;
    top: -25%;
    object-fit: scale-down;
  }

  .regLink {
    font-size: 80%;
  }

  form {
    background: linear-gradient(#482366, #280d3d);
    color: #fff;
    border-radius: 28px;
    margin: auto;
    margin-top: 30px;
    max-width: 375px;
    position: fixed;
    bottom: 5vh;
    right: 5vw;
    padding: 1.8vw 1.5vw;
    max-height: 100%;
    text-align: center;

    a,
    .link {
      color: #f4b64e;
    }

    input {
      width: 100%;
      background: ${COLORS.lightGray};
      border: 1px solid #ccc;
      padding: 12px 18px;
      border-radius: 8px;
      margin-top: 20px;

      &::placeholder {
        color: '#555555';
      }
    }

    .forget-pass {
      color: ${COLORS.primaryTrans};
      margin-top: 10px;
      font-size: 0.9em;
    }

    .login-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      border-radius: 50%;
      letter-spacing: 1px;
      /* background: linear-gradient(#8468ce, #96f19c); */
      background: var(--gold);
      width: 60px;
      height: 60px;
      cursor: pointer;
      opacity: 1;
      outline: none;
      border: none;
      input {
        cursor: pointer;
        opacity: 0;
        padding: 0;
        margin: 0;
        display: none;
      }
    }
    .title {
      font-weight: bold;
      font-size: 24px;
      margin: 0;
      color: #f4dd94;
    }
    .white-arrow {
      width: 24px;
      line-height: 60px;
      cursor: pointer;
    }
  }

  ${SIZES.mobile} {
    .mobile-show {
      display: block;
    }
  }
`

export const MobileStyle = styled.section`
  display: flex;
  flex-direction: column;

  a,
  .link {
    color: #f4b64e;
  }

  .login-wrapper {
    background: linear-gradient(#482366, #280d3d);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }

  .regLink {
    font-size: 80%;
    text-align: center;
  }

  .title {
    width: 100%;
    text-align: center;
    border-bottom: 2px solid #fff;
    padding: 1rem;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }

  form {
    padding: 1rem 2rem;

    input {
      width: 100%;
      background: ${COLORS.lightGray};
      border: 1px solid #ccc;
      padding: 12px 18px;
      border-radius: 8px;
      margin-top: 20px;

      &::placeholder {
        color: #555555;
      }
    }

    .login-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      border-radius: 50%;
      letter-spacing: 1px;
      background: linear-gradient(#8468ce, #96f19c);
      width: 60px;
      height: 60px;
      cursor: pointer;
      opacity: 1;
      outline: none;
      border: none;
      input {
        cursor: pointer;
        opacity: 0;
        padding: 0;
        margin: 0;
        display: none;
      }
    }

    .white-arrow {
      width: 24px;
      line-height: 60px;
      cursor: pointer;
    }
  }
`
