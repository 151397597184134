import { useCallback, useEffect } from 'react'
import { post } from 'src/apis/apiService'
import config from 'src/config'

interface UseTrackProps {
  trackUrl?: string
  logEntryUrl?: string
  enable?: boolean
}

export const useTrack = ({ 
  trackUrl = `${config.apiUrl}/api/track`, 
  logEntryUrl = `${config.apiUrl}/api/log-entry` 
}: UseTrackProps) => {
  const heartbeatUrl = `${config.apiUrl}/api/heartbeat`

  const resetSession = useCallback(() => {
    window.location.reload()
  }, [])

  // Heartbeat
  const sendHeartbeat = useCallback(() => {
    post(
      heartbeatUrl,
      (_, status) => {
        status === 200 ? console.log('Heartbeat OK') : resetSession()
      },
      (error) => {
        console.error('Session validation error:', error)
        resetSession()
      }
    )
  }, [heartbeatUrl, resetSession])

  useEffect(() => {
    const checkHeartbeat = setInterval(sendHeartbeat, 30000)

    return () => {
      clearInterval(checkHeartbeat)
    }
  }, [])

  // Log Entry Time
  const log_entry = useCallback(() => {
    post(
      logEntryUrl,
      (_) => {},
      (error) => {
        console.error(error)
      }
    )
  }, [logEntryUrl])

  // Time Duration Tracking and Log Exit
  const sendBeacon = (startTime, jwtToken) => {
    const timeSpent = Date.now() - startTime
    const data = { timeSpent, jwtToken }
    let headers = {
      type: 'application/json; charset=utf-8',
    }
    const blob = new Blob([JSON.stringify(data)], headers)
    navigator.sendBeacon(trackUrl, blob)
  }

  useEffect(() => {
    let startTime = Date.now()
    const jwtToken = localStorage.getItem('jwt_token')

    log_entry()

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        sendBeacon(startTime, jwtToken)
      }

      if (document.visibilityState === 'visible') {
        log_entry()
        startTime = Date.now()
      }
    }

    const handleBeforeUnload = () => {
      sendBeacon(startTime, jwtToken)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('beforeunload', handleBeforeUnload)
      sendBeacon(startTime, jwtToken)
    }
  }, [])
}
