import AOS from 'aos'
import React, { Suspense, useEffect, useMemo } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import config from './config'
import routes from './routes'

import { PreCache } from './containers/PreCache'

import ReactGA from 'react-ga4'
import Media from 'react-media'
import { SIZES } from './styles/theme'

// import InstiRegistration from './pages/registration/InstiRegistration'
// import PhysicalRegistration from './pages/registration/PhysicalRegistration'
// import PostRegisterPage from './pages/registration/PostRegisterPage'
// import PreRegisterPage from './pages/registration/PreRegisterPage'
// import VirtualRegistration from './pages/registration/VirtualRegistration'
import { LoginPage } from './pages/LoginPage'
import WelcomePage from './pages/WelcomePage'
import { LobbyPage } from './webOnlyPages/LobbyPage'
import PrivateRoute from './containers/PrivateRoute'
import AuditoriumContainer from './containers/AuditoriumContainer'
import MobileLobby from './mobileOnlyPages/MobileLobby'
import MobileNotifications from './mobileOnlyPages/MobileNotifications'
import BoothPage from './webOnlyPages/BoothPage'
import MobileBooth from './webOnlyPages/MobileBooth'
// import PhysicalProgrammePage from './pages/PhysicalProgrammePage'
// import SpeakerProfilePage from './pages/SpeakerProfilePage'
// import TestLivePage from './pages/TestLivePage'
import ActiveUserCountPage from './pages/ActiveUserCountPage'
// import { DesktopOnDemand } from './webOnlyPages/DesktopOnDemand'
// import { MobileOnDemand } from './mobileOnlyPages/MobileOnDemand'
import RegisterFormPage from './pages/registration/RegisterFormPage'
import ThankYou from './pages/registration/ThankYou'
import ZoomContainer from './pages/ZoomContainer'
import ZoomExit from './pages/ZoomExit'
const ZoomPage = React.lazy(() => import('./pages/ZoomPage'))

declare global {
  interface Window {
    chaport: any
  }
}
ReactGA.initialize('G-YTL11PFZRT')

function App() {
  const location = useLocation()

  useEffect(() => {
    document.title = config.eventName
    window.chaport.q('hideLauncher')

    AOS.init({
      easing: 'ease-in-out',
      once: true,
      offset: 50,
      anchorPlacement: 'top-bottom',
    })
  }, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      })
    }
  }, [location])

  return useMemo(() => {
    return (
      <Suspense fallback={<p className="text-center">Loading...</p>}>
        <div id="innerBody">
          <PreCache deviceType={'desktop'}>
            <Switch>
              <Route exact path={routes.register} component={RegisterFormPage} />
              <Route exact path={routes.root} component={LoginPage} />
              <Route exact path={routes.login} component={LoginPage} />
              <Route exact path={routes.thankyou} component={ThankYou} />
              <PrivateRoute blockedRoles={['participant', 'exhibitor', 'speaker']} exact path="/realtime" component={ActiveUserCountPage} />
            </Switch>
          </PreCache>

          <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
            {(matches) =>
              matches ? (
                // MOBILE ROUTES
                <PreCache deviceType={'mobile'}>
                  <Switch>
                    <PrivateRoute mobileNav path={routes.lobby} component={MobileLobby} />
                    <PrivateRoute mobileNav path={routes.replay} component={AuditoriumContainer} />
                    <PrivateRoute mobileNav path={routes.notifications} component={MobileNotifications} />
                    <PrivateRoute mobileNav path="/booths/:boothId" component={MobileBooth} />
                    {/* <PrivateRoute mobileNav path={routes.onDemand} component={MobileOnDemand} /> */}
                    <PrivateRoute exact path={routes.zoom} component={ZoomPage} />
                    <PrivateRoute path={routes.joinSession} component={() => <ZoomContainer mobile />} />
                    <PrivateRoute path={routes.leaveSession} component={ZoomExit} />
                  </Switch>
                </PreCache>
              ) : (
                // DESKTOP ROUTES
                <PreCache deviceType={'desktop'}>
                  <Switch>
                    <PrivateRoute fixedNav path={routes.lobby} component={LobbyPage} />
                    <PrivateRoute fixedNav path={routes.replay} component={AuditoriumContainer} />
                    <PrivateRoute fixedNav path="/booths/:boothId" component={BoothPage} />
                    {/* <PrivateRoute fixedNav path={routes.onDemand} component={DesktopOnDemand} /> */}
                    <PrivateRoute exact path={routes.zoom} component={ZoomPage} />
                    <PrivateRoute path={routes.joinSession} component={ZoomContainer} />
                    <PrivateRoute path={routes.leaveSession} component={ZoomExit} />
                  </Switch>
                </PreCache>
              )
            }
          </Media>
        </div>
      </Suspense>
    )
  }, [])
}

export default App
