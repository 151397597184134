import config from '../config'

export const LIVE_SESSION_APIS = {
  GET_SESSIONS: `${config.apiUrl}/api/live_sessions`,
  GET_SESSION: (sessionId) => `${config.apiUrl}/api/live_sessions/${sessionId}`,
  GET_IDS: `${config.apiUrl}/api/live_sessions_ids`,
  JOIN_SESSION: (sessionId) => `${config.apiUrl}/api/live_sessions/${sessionId}/join`,

  // Tracking and Zoom
  TRACK_USER: `${config.apiUrl}/api/track`,
  HEARTBEAT: `${config.apiUrl}/api/heartbeat`,
  JOIN_SESSION: (sessionId) => `${config.apiUrl}/api/live_sessions/${sessionId}/join`,
  BEACON: `${config.apiUrl}/api/beacon`,
  ZOOM_SIG: `${config.apiUrl}/api/zoom_signature`,
  LOG_AUD_ENTRY: `${config.apiUrl}/api/entry-log`,
}