import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import MobileNav from 'src/components/MobileNav'
import NoticeBar from 'src/components/NoticeBar'
import TopNav from 'src/components/TopNav'
import routes from 'src/routes'
import Amplitude from 'src/utils/amplitudeHelper'
import styled from 'styled-components'
import { useAuditoriumController } from 'src/utils/useAuditoriumController'
import { useTrack } from 'src/utils/useTrack'


export const ZoomStyle = styled.div`
  height: calc(100dvh - 50px);
  width: 100%;
  padding-top: 30px;

  .zoomContainer {
    height: 100%;
  }

  @media (min-width: 820px) {
    height: 100vh;
  }
`

const ZoomContainer = ({ mobile = false }) => {
  useTrack({})
  useAuditoriumController()
  const navigate = useHistory()
  const { currentUser} = useSelector((state) => state.auth)

 
  useEffect(() => {
    Amplitude.visit('Zoom')
    const body = document.querySelector('#root')
    body?.scrollIntoView()
  }, [])

  useEffect(() => {
    const listenMsg = (e: any) => {
      if (e.data === 'LEAVE_ZOOM') {
        navigate.push(routes.auditorium)
      }
    }
    window.addEventListener('message', listenMsg)
    return () => {
      window.removeEventListener('message', listenMsg)
    }
  })

  return (
    <>
    <NoticeBar/>
    {mobile ? <MobileNav /> : <TopNav currentUser={currentUser} /> }
    <ZoomStyle>
      <div className="zoomContainer">
        <iframe
          width="100%"
          height="100%"
          src={`${routes.zoom}`}
          title="Zoom"
        ></iframe>
      </div>
    </ZoomStyle>
    </>
  )
}

export default ZoomContainer
