import React from 'react'
import styled from 'styled-components'
import FrontPageContainer from 'src/containers/FrontPageContainer'

const Wrapper = styled.div`
  height: 100%;
  

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 1rem;
    border: 2px dashed #fff;
    padding: 2rem 0;
  }

  .box {
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    p {
      font-size: 22px;
      text-align: center;
    }
  }

  @media (min-width: 1280px) {
    padding: 0 4rem;

    .content {
      padding: unset;
    }
    
    .box {
      p {
        font-size: 24px;
      }
    }

    .content {
      padding: 0 1rem;
    }
  }
`

export default function ThankYou() {
  return (
    <FrontPageContainer thankYou>
      <Wrapper>
        <div class="content">
          <div className='box'>
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 40C7.5 22.0507 22.0507 7.5 40 7.5C57.9493 7.5 72.5 22.0507 72.5 40C72.5 57.9493 57.9493 72.5 40 72.5C22.0507 72.5 7.5 57.9493 7.5 40ZM52.0343 33.9531C52.8369 32.8296 52.5766 31.2682 51.4531 30.4657C50.3296 29.6631 48.7682 29.9234 47.9657 31.0469L37.181 46.1455L31.7678 40.7322C30.7915 39.7559 29.2085 39.7559 28.2322 40.7322C27.2559 41.7085 27.2559 43.2915 28.2322 44.2678L35.7322 51.7678C36.2519 52.2874 36.9738 52.5521 37.7062 52.4915C38.4386 52.4309 39.1072 52.0511 39.5343 51.4531L52.0343 33.9531Z" fill="white"/>
            </svg>
            <p>Thank you for your registration! <br/>
            You will receive a confirmation email soon</p>
          </div>
        </div>
      </Wrapper>
    </FrontPageContainer>
  )
}
