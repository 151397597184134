import React, { useCallback, useEffect, useState } from 'react'
import { Loader } from 'src/components/shared/Loader'
import { cacheWebImages } from '../utils/cacheHelper'

// import regBg from '../images/register/register_bg.png'
// import regBgMobile from '../images/register/register_bg_mobile.png'
// import registerKv from '../images/register/register_visual.png'
// import checkIcon from '../images/register/check_icon.png'
// import infoIcon from '../images/backdrops/info-icon.png'
// import profileIcon from '../images/backdrops/speaker-icon.png'
// import playIcon from '../images/backdrops/play-icon.png'

// import lobby from '../images/backdrops/lobby.jpg'
// import auditorium from '../images/backdrops/auditorium.jpg'
// import entranceArrow from '../images/backdrops/lobby-arrow.png'
// import auditoriumPoster from '../images/backdrops/auditorium-poster.jpg'
// import bannerM from '../images/backdrops/mobile-banner.jpg'
// import helpdeskStand from '../images/backdrops/helpdesk_stand.png'
// import speakersStand from '../images/backdrops/speakersprofile_stand.png'
// import auditoriumEntrance from '../images/backdrops/auditorium_entrance.png'
// import exhibitionEntrance from '../images/backdrops/exhibition_entrance.png'
// import ondemandEntrance from '../images/backdrops/ondemand_entrance.png'
// import galleryBg from '../images/backdrops/gallery-bg.jpg'
// import galleryMask from '../images/backdrops/galleryMask.png'

import landingBg from '../images/growth-summit/general/landing-bg.jpg'
import masthead from '../images/growth-summit/registration/masthead.png'
import mastheadMobile from '../images/growth-summit/general/masthead-mobile.png'
import organizerLogoMobile from '../images/growth-summit/general/organizer-logo.png'
import girlMobile from '../images/growth-summit/registration/girl-mobile.png'
import countdownBg from '../images/growth-summit/countdown/countdown-bg.jpg'
import whiteRightArrow from '../images/growth-summit/login/right-arrow-white.png'
import loginBg from '../images/growth-summit/login/login-bg.jpg'
import mobileLoginBg from '../images/growth-summit/login/mobile-login-bg.jpg'
import lobbyBg from '../images/growth-summit/lobby/lobby-bg.jpg'
import auditoriumBg from '../images/growth-summit/auditorium/auditorium-bg.jpg'
import mobileAuditoriumBg from '../images/growth-summit/auditorium/auditorium-mobile-bg.jpg'
import zoom from '../images/growth-summit/auditorium/Live-Zoom.png'
import boothBg from '../images/growth-summit/booth/booth-bg.jpg'
import booth from '../images/growth-summit/booth/booth.png'
import mobileBoothBg from '../images/growth-summit/booth/mobile-booth-bg.jpg'
import arrow from '../images/growth-summit/lobby/arrow.png'
import infoIcon from '../images/growth-summit/lobby/info.png'
import mobileLobbyBg from '../images/growth-summit/lobby/mobile-lobby.jpg'
import agendaIcon from '../images/growth-summit/lobby/agenda.png'
import boothIcon from '../images/growth-summit/lobby/booth.png'
import auditoriumIcon from '../images/growth-summit/lobby/auditorium.png'
import speakerIcon from '../images/growth-summit/lobby/speakers.png'
import helpdeskIcon from '../images/growth-summit/lobby/helpdesk.png'
import boothInfoIcon from '../images/growth-summit/booth/info.png'
import boothPlayIcon from '../images/growth-summit/booth/Play.png'
import boothPdfIcon from '../images/growth-summit/booth/PDF.png'
import boothChatIcon from '../images/growth-summit/booth/Chat.png'
import mobileAuditoriumKv from '../images/growth-summit/auditorium/auditoriumKv.png'

import speaker1 from '../images/growth-summit/speakers/speaker1.jpg'
import speaker2 from '../images/growth-summit/speakers/speaker2.jpg'
import speaker3 from '../images/growth-summit/speakers/speaker3.jpg'
import speaker4 from '../images/growth-summit/speakers/speaker4.jpg'
import speaker5 from '../images/growth-summit/speakers/speaker5.jpg'
import speaker6 from '../images/growth-summit/speakers/speaker6.jpg'
import speaker7 from '../images/growth-summit/speakers/speaker7.jpg'
import speaker8 from '../images/growth-summit/speakers/speaker8.jpg'
import speaker9 from '../images/growth-summit/speakers/speaker9.jpg'
import speaker10 from '../images/growth-summit/speakers/speaker10.jpg'
import speaker11 from '../images/growth-summit/speakers/speaker11.jpg'
import speaker12 from '../images/growth-summit/speakers/speaker12.jpg'
import speaker13 from '../images/growth-summit/speakers/speaker13.jpg'

export const fixedAssets = {
  // registerKv,
  // regBg,
  // regBgMobile,
  // checkIcon,
  // bannerM,
  // helpdeskStand,
  // speakersStand,
  // infoIcon,
  // profileIcon,
  // playIcon,
  // galleryBg,
  // galleryMask,

  // lobby,
  // auditorium,
  // auditoriumPoster,
  // entranceArrow,
  // auditoriumEntrance,
  // exhibitionEntrance,
  // ondemandEntrance,
  // New
  whiteRightArrow,
  masthead,
  zoom,
  infoIcon,
  speaker1,
  speaker2,
  speaker3,
  speaker4,
  speaker5,
  speaker6,
  speaker7,
  speaker8,
  speaker9,
  speaker10,
  speaker11,
  speaker12,
  speaker13,
}

export const desktopAssets = {
  landingBg,
  countdownBg,
  loginBg,
  lobbyBg,
  auditoriumBg,
  boothBg,
  booth,
  arrow,
}

export const mobileAssets = {
  mastheadMobile,
  organizerLogoMobile,
  girlMobile,
  mobileLoginBg,
  mobileAuditoriumBg,
  mobileBoothBg,
  mobileLobbyBg,
  agendaIcon,
  boothIcon,
  auditoriumIcon,
  speakerIcon,
  helpdeskIcon,
  boothInfoIcon,
  boothPlayIcon,
  boothPdfIcon,
  boothChatIcon,
  mobileAuditoriumKv,
}

export const PreCache = ({ deviceType, children }) => {
  const [pageLoading, setPageLoading] = useState(false)

  const preloadAssets = useCallback(async () => {
    setPageLoading(true)
    await cacheWebImages(
      Object.values({
        ...fixedAssets,
        ...(deviceType === 'mobile' ? mobileAssets : desktopAssets),
      })
    )
    setPageLoading(false)
  }, [])

  useEffect(() => {
    preloadAssets()
  }, [deviceType, preloadAssets])

  return (
    <Loader loading={pageLoading} fullPage>
      <>{children}</>
    </Loader>
  )
}
