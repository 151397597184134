import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import { SIZES } from '../../styles/theme'
import Axios from 'axios'
import config from '../../config'
import { toast } from 'react-toastify'
import routes from '../../routes'
import { desktopAssets, fixedAssets, mobileAssets } from 'src/containers/PreCache'
import FrontPageContainer from 'src/containers/FrontPageContainer'
import { post } from 'src/apis/apiService'

const OldPage = styled.div`
  min-height: 100vh;
  background: #280D3D;
  color: #000000;
  text-align: center;

  .masthead {
    width: 100%;
    max-width: 100%;
  }
  .organizers {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .mobile-show {
    display: none;
  }

  @media (min-width: 1280px) {

    background-image: url(${desktopAssets.landingBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-bottom: 2em;
    padding-top: 2em;

    .form-container div:first {
      margin-top: 0;
    }
  }
`

export default function RegisterFormPage() {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [salutation, setSalutation] = useState('')
  const [fullName, setFullName] = useState('')
  const [icNo, setIcNo] = useState('')
  const [mmc, setMmc] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [profession, setProfession] = useState('')
  const [others, setOthers] = useState('')
  const [placeOfPractice, setPlaceOfPractice] = useState("")
  const [state, setState] = useState("")
  const [sector, setSector] = useState("")
  const [question, setQuestion] = useState("")
  const [webinar, setWebinar] = useState("")

  const [emailFormatted, setEmailFormatted] = useState(true)
  const [validationError, setValidationError] = useState(false)
  const [icFormatError, setIcFormatError] = useState(false)

  const titleList = ['Mr', 'Mrs', 'Ms', 'Madam', 'Datuk', 'Dato', 'Datin', 'Prof.', 'Assoc. Prof.', 'Dr']
  const professionList = [
    "Dietitian",
    "General practitioner",
    "Family medicine specialist",
    "Nurse",
    "Nutritionist",
    "Medical officer",
    "Pediatrician",
    "Pharmacist",
    "Psychologist",
    "Other"
  ];
  
  const stateList = ['Selangor', 'Kuala Lumpur', 'Sarawak', 'Johor', 'Sabah', 'Penang', 'Perak', 'Pahang', 'Negeri Sembilan', 'Kedah', 'Malacca', 'Terengganu', 'Kelantan', 'Labuan', 'Perlis' ]
  const sectorList = ['Private', 'Government']
  const _stateList = stateList.sort((a, b) => a.localeCompare(b))

  const handleEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
    const regexExp = /\S+@\S+\.\S+/
    const validatedEmail = regexExp.test(e.target.value)
    if (validatedEmail) {
      setEmailFormatted(true)
    } else {
      setEmailFormatted(false)
    }
  }

  const handleNric = (e) => {
    const input = e.target.value;
    // Remove non-numeric characters
    const numericInput = input.replace(/\D/g, '');
    // Set the value to the first 12 digits
    setIcNo(numericInput.substring(0, 12));
    // Set the error if length is less than 12
    setIcFormatError(numericInput.length < 12);
  }
  

  const handlePhoneNumber = (e) => {
    const input = e.target.value;
    // Remove non-numeric characters
    const numericInput = input.replace(/\D/g, '');
    // Set the value to the first 11 digits
    setPhone(numericInput.substring(0, 11));
  }
  

  const handleOther = (e) => {
    setProfession(e.target.value)
    setOthers("")
  }
  const validated = emailFormatted || !icFormatError

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    if (!hasEmptyField && validated) {
      let finalProfession = profession
      if (profession === 'Other') {
        finalProfession = others.trim()
      }
      const options = {
        title: salutation,
        full_name: fullName.trim(),
        nric: icNo.trim(),
        mmc: mmc.trim(),
        mobile_number: phone.trim(),
        email,
        profession: finalProfession,
        place_of_practice: placeOfPractice.trim(),
        state,
        sector,
        question: question.trim(),
        hear_about: webinar.trim(),
      }
      post(
        `${config.apiUrl}/api/users`,
        (result) => {
          toast('Successfully Registered')
          history.push(routes.thankyou)
        },
        (error) => {
          if (error?.response?.data?.message) {
            toast(error.response.data.message)
          } else {
            toast(error.message)
          }
          console.log('error', error)
          console.log('error response', error.response)
        },
        {
          user: options,
        }
      )
    } else {
      setValidationError(true)
      setLoading(false)
      toast('Please ensure all fields are filled in accurately before proceeding')
    }
  }

  const hasEmptyField = !(
    salutation.length &&
    fullName.length &&
    icNo.length &&
    mmc.length &&
    phone.length &&
    email.length &&
    profession.length &&
    ((profession === 'Other') == (others.length > 0)) &&
    placeOfPractice.length &&
    state.length &&
    sector.length
  )

  return (
    <FrontPageContainer>
      <FormWrapper className="register-form" noGutters>
            <Col md={6} className="form-container">
              <Form.Group >
                <select
                  className="form-input"
                  value={salutation}
                  name="salutation"
                  onChange={(e) => setSalutation(e.target.value)}
                >
                  <option value="" disabled selected hidden>Title *</option>
                  {titleList.map((item, i) => (
                    <option key={`option-${i}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Group>
                <input
                  className="form-input"
                  required
                  type="text"
                  placeholder='Full Name as per IC *'
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <input
                  className="form-input"
                  type="text"
                  placeholder="IC Number * (for CPD application)"
                  value={icNo}
                  onChange={(e) => handleNric(e)}
                />
              </Form.Group>

              <Form.Group>
                <input
                  className="form-input"
                  type="text"
                  value={mmc}
                  placeholder="MMC Number * (for CPD application)"
                  onChange={(e) => setMmc(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={phone}
                  placeholder="Mobile Number *"
                  onChange={(e) => handlePhoneNumber(e)}
                />
              </Form.Group>

              <Form.Group>
                <input
                  className="form-input"
                  required
                  type="email"
                  value={email}
                  placeholder="Email Address *"
                  onChange={handleEmailInput}
                />
              </Form.Group>
              {validationError && !email.length && <p className="error-message m-0">* Email Address is required.</p>}
              {!emailFormatted && <p className="error-message">* Please ensure email address format is valid</p>}

              <Form.Group>
                <select
                  className="form-input"
                  value={profession}
                  name="profession"
                  onChange={handleOther}
                >
                  <option value="" disabled selected hidden>Profession *</option>
                  {professionList.map((item, i) => (
                    <option key={`profession-${i}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Form.Group>
              {profession === 'Other' &&
                <Form.Group>
                  <input
                    className="form-input"
                    required
                    type="text"
                    value={others}
                    placeholder="Other, please specify."
                    onChange={(e) => setOthers(e.target.value)}
                  />
                </Form.Group>
              }
            </Col>

            {/* form right side */}
            <Col md={6} className="form-container">
              <Form.Group>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={placeOfPractice}
                  placeholder="Place of Practice *"
                  onChange={(e) => setPlaceOfPractice(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <select
                  className="form-input"
                  value={state}
                  name="state"
                  onChange={(e) => setState(e.target.value)}
                >
                  <option value="" disabled selected hidden>State *</option>
                  {_stateList.map((item, i) => (
                    <option key={`state-${i}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Group>
                <select
                  className="form-input"
                  value={sector}
                  name="sector"
                  onChange={(e) => setSector(e.target.value)}
                >
                  <option value="" disabled selected hidden>Sector *</option>
                  {sectorList.map((item, i) => (
                    <option key={`sector-${i}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <div className='optional'>
                <label>Do you have any question for the speakers?</label>
                <textarea rows={3} value={question} onChange={(e) => setQuestion(e.target.value)} placeholder="Type here..."></textarea>
              </div>
              <div className='optional'>
                <label>How did you hear about this webinar?</label>
                <textarea rows={3} value={webinar} onChange={(e) => setWebinar(e.target.value)} placeholder="Type here..."></textarea>
              </div>
              {hasEmptyField && <p className="error-message m-0">* Please fill in the required field to proceed.</p>}
            </Col>
            {/* <div className="privacy mb-2">
              <div className="form-check">
                <label className="form-check-label text-left">
                  <span className="text-danger">*</span>&nbsp; By filling this form, you also agree to be bound by following Abbott Laboratories (M) Sdn Bhd <b>Privacy Policy</b> accessible at the link &nbsp; 
                  <a href="https://www.my.abbott/privacy-policy.html" target="_blank" rel="noopener noreferrer">
                    https://www.my.abbott/privacy-policy.html
                  </a>.
                </label>
              </div>
            </div> */}
             
            {/* bottom */}
            <div className="d-flex justify-content-center w-100 mt-4">
              <Button
                type="submit"
                className="submit-btn"
                variant="default"
                // disabled={loading}
                onClick={handleSubmit}
              >
                <div>{loading ? 'Loading...' : 'REGISTER NOW'}</div>
              </Button>
            </div>
          </FormWrapper>
    </FrontPageContainer>
  )
}

const FormWrapper = styled(Row)`

  .form-group {
    width: 100%;
    margin: 18px auto;
    margin-bottom: 0;
    background: #FFFFFF;
    border: none;
    border-radius: 6px;
    font-weight: 600;
    text-align: center;
    padding-right: 8px;
      
    input[type='tel'], .form-input {
      display: block;
      color: #4D4D4D;
      width: 100%;
      font-weight: 500;
      background: transparent;
      border: none;
      padding: 14px 24px;
      outline: none;
      text-align: center;

      &::placeholder {
        color: #4D4D4D;
        font-size: 14px;
      }
    }
  }

  .form-container {
    padding: 0 10px;
  }

  select.form-input {
    text-align-last: center;
  }

  h6.subtitle {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    padding: 0 10px;
  }
  .error-message {
    font-weight: 500;
    color: red;
  }

  .optional {
    margin: 12px 0;
    label {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
    }
    textarea {
      display: block;
      color: #4D4D4D;
      border-radius: 6px;
      width: 100%;
      font-weight: 500;
      background: #fff;
      border: none;
      padding: 4px 8px;
      outline: none;
      &::placeholder {
        color: #4D4D4D;
        font-size: 14px;
      }
    }
  }
  div.privacy {
    margin: 0 auto;
    margin-top: 32px;
    color: #fff;
    .form-check {
      display: flex;
      align-items: flex-start;
      padding: 0;
      font-weight: 500;
      
      .form-check-label {
        font-size: 14px;
        margin-left: 8px;
        a {
          text-decoration: underline;
        }
      }
    }
  }

  button.submit-btn {
    background: linear-gradient(90deg, #BC8B4B 0.29%, #E7CA82 13.01%, #D7B367 19.38%, #FAF4BF 25.74%, #F4DD94 55.43%, #D7B367 72.4%, #FFF5BD 81.94%, #F5DE95 90.42%, #BC8B4B 106.33%);
    box-shadow: 0 5px 4px -4px #000000;
    width: 35%;
    color: var(--primary);
    font-weight: 450;
    border: none;
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    padding: 11px 18px;
    font-size:24px;
    outline: none;
    margin-top: 2%;

    ${SIZES.mobile} {
      width: 100%;
      padding: 10px 24px;
    }
    &:disabled {
      cursor: not-allowed;
      background: grey;
    }
    div {
      line-height: 32px;
    }
  }
`