import React, { useEffect } from 'react'
import { desktopAssets, fixedAssets } from 'src/containers/PreCache'
import Amplitude from 'src/utils/amplitudeHelper'
import styled from 'styled-components'
import config from '../config'
import { SIZES } from '../styles/theme'
import { useAutoMute } from '../utils/useAutoMute'
import PageWithBg from 'src/components/PageWithBg'
import { showGlobalModal } from 'src/ducks/layout'
import { GlobalModalTypes } from 'src/components/GlobalModals/GlobalModalContainer'
import { useDispatch } from 'react-redux'
import routes from 'src/routes'
import { useHistory } from 'react-router'

const Page = styled.div`
  overflow: hidden;

  .content {
    ${config.layout.auditoriumVid}
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 649px;
    height: 365px;
    iframe {
      height: 100%;
      width: 100%;
    }
    margin-top: 10px;
  }

  .chat {
    max-width: 425px;
    position: fixed;
    padding-top: ${SIZES.navHeight};
    top: 0;
    left: 70%;
    bottom: 0;
    right: 5%;
    z-index: 30;
    margin: 0 auto;
    background: #242526;
  }
`

export default function AuditoriumPage({ currentUser }) {
  useAutoMute()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = `${config.eventName} | Auditorium`
    Amplitude.visit('Auditorium - Replay')
  }, [])

  return (
    <Page>
      <PageWithBg bgImg={desktopAssets.auditoriumBg}>
        <Wrapper>
          <div className="video">
            <div className="iframe">
              <iframe title="Replay" src="https://vimeo.com/showcase/11327434/embed?autoplay=1&loop=1&autopause=0&muted=1" allowFullscreen frameBorder="0" />
            </div>
          </div>

          {/* <div className="mx-auto chat">
          <iframe 
            src="https://app.sli.do/event/3JfMC7uYMJbDJ4do9cFcCA" 
            height="100%" 
            width="100%" 
            frameBorder="0" 
            style={{ minHeight: "600px" }} 
            title="Slido"
          ></iframe>
        </div> */}

          <div className="controls d-flex flex-row">
            <Btn onClick={() => dispatch(showGlobalModal(GlobalModalTypes.Agenda))}>Agenda</Btn>
            <Btn onClick={() => dispatch(showGlobalModal(GlobalModalTypes.Speakers))}>Speakers</Btn>
          </div>
        </Wrapper>
      </PageWithBg>
    </Page>
  )
}

const Wrapper = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  display: flex;
  flex-direction: column;
  align-items: center;

  
  iframe {
    width: 100%;
    height: 100%;
  }

  .video {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .iframe {
      background: #000;
      width: 68%;
      display: flex;
      justify-content: center;
      align-items: center;
      aspect-ratio: 16/9;
      flex-direction: column;
      cursor: pointer;
      height: 495px;

      img {
        max-width: 50%;
      }
    }
  }

  .controls {
    gap: 2rem;
    margin-top: 2%;
  }

  @media (min-width: 1920px) {
    .video {
      max-width: 1050px;

      .iframe {
        width: 100% !important;
        min-height: 550px;
      }
    }
  }
`

const Btn = styled.div`
  border-radius: 4rem;
  color: #fff;
  text-transform: capitalize;
  font-weight: bold;
  padding: 9px 43px;
  /* background: linear-gradient(118deg, #323a9b -2.53%, #32b24a 107.38%); */
  background: var(--secondary);
  cursor: pointer;
  width: fit-content;
  border: 0px;
  font-size: 1.2rem;
  transition: all 200ms ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  ${SIZES.mobile} {
    width: 100%;
    font-size: 8px 41px;
  }
`
