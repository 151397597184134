import styled from 'styled-components'

interface PinWrapperProps {
  css: string
}

export const PinWrapper = styled.div<PinWrapperProps>`
  ${(props) => props.css}
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;

  .pinImage,
  .pinText {
    position: absolute;
    text-shadow: -3px -3px 3px rgba(255, 255, 255, 0.1), 3px 3px 3px rgba(0, 0, 0, 0.7);
  }
  &:hover {
    .pinText {
      font-weight: 800;
      color: #fff;
    }
  }

  .pinImage {
    z-index: 10;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    animation: ${(props) => (props.textCss ? 'floating 4s infinite' : 'heartbeat 7s infinite')};
    transition: all 300ms ease-in;
    transform: rotate(0deg);

    &:hover {
      animation-name: ${(props) => (props.textCss ? 'floating' : 'bounce')};
      animation-duration: ${(props) => (props.textCss ? '4s' : '1s')};
      animation-fill-mode: ${(props) => (props.textCss ? 'none' : 'both')};
      transform: scale(1.1) rotate(10deg);
    }
  }
  .textParent {
    perspective: 200px;
    position: absolute;
    z-index: 1000;
    inset: 0;
  }
  .pinText {
    color: #fff;
    font-size: 33px;
    letter-spacing: 1px;
    font-weight: 500;
    /* text-shadow: -2px -2px 2px rgba(255,255,255,.1), 2px 2px 2px rgba(0,0,0,.5); */
    z-index: 1000;
    text-align: center;
    transform-style: preserve-3d;
    transition: all 300ms ease-in;
    ${(props) => props.textCss}

    span span {
      position: relative;
      bottom: -1em;
      opacity: 0;
      animation: move-text 3s infinite forwards;
    }
  }
  @keyframes move-text {
    0% {
      bottom: -0.25em;
      opacity: 1;
    }
    50% {
      bottom: 0.25em;
    }
    100% {
      bottom: 0;
      opacity: 1;
    }
  }
`
