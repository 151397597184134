import React from 'react'
import styled from 'styled-components'
import { desktopAssets, fixedAssets, mobileAssets } from './PreCache'
import { Col, Row } from 'react-bootstrap'

export default function FrontPageContainer({ children, countdown = false, thankYou = false }) {
  return (
    <FrontPageTemplate countdown={countdown} thankYou={thankYou}>
      <img src={mobileAssets.organizerLogoMobile} alt="organizer" className="d-block d-lg-none img-fluid" />
      <img src={mobileAssets.mastheadMobile} alt="masthead" className="d-block d-lg-none img-fluid p-3" />
      <Row id="main" noGutters className="px-4 pt-2 pb-3">
        <Col md={4} className="d-none d-lg-block">
          <img src={fixedAssets.masthead} alt="event-banner" className="img-fluid" />
        </Col>
        <Col md={6} className="text-left ml-lg-4">
          {children}
        </Col>
        <Col md={1}></Col>
      </Row>
      <img src={mobileAssets.girlMobile} alt="girl" className="d-block d-lg-none img-fluid girl" />
    </FrontPageTemplate>
  )
}

const FrontPageTemplate = styled.div`
    min-height: 100vh;
    background: #280d3d;
    color: #fff;
    text-align: center;

    .girl {
      ${props => props.thankYou ? `margin-top: -8rem` : ''}
    }

    @media (min-width: 1280px) {
      background-image: url(${props => props.countdown ? desktopAssets.countdownBg : desktopAssets.landingBg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      padding-bottom: 2em;
      padding-top: 2em;

      .form-container div:first {
        margin-top: 0;
      }
    }
  `
