import React from "react"
import { useEffect } from "react"

const ZoomExit = () => {
  useEffect(() => {
    window.parent.postMessage('LEAVE_ZOOM', '*')
  }, [])

  return <div>Exiting...</div>
}

export default ZoomExit