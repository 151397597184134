import React, { useState } from 'react'
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component'
import { useHistory } from 'react-router-dom'
import PageWithBg from 'src/components/PageWithBg'
import { desktopAssets } from 'src/containers/PreCache'
import styled from 'styled-components'
import Amplitude from '../utils/amplitudeHelper'

import { DocumentViewer } from 'src/components/GlobalModals/DocumentViewer'
import DownloadModal from 'src/components/GlobalModals/DownloadModal'
import IframeModal from 'src/components/GlobalModals/IframeModal'
import InfoModal from 'src/components/GlobalModals/InfoModal'
import { Loader } from 'src/components/shared/Loader'
import routes from 'src/routes'

const Page = styled.div`
  overflow: hidden;
  color: #505c7c;

  #button-container {
    position: absolute;
    padding-top: 30px;
    top: 2vh;
    left: 2%;
    .hot-buttons {
      width: 125px;
    }
    .back-hall {
      svg {
        height: 26px;
        width: 24px;
      }
    }
  }
  .hot-buttons,
  .hot-actions {
    background: var(--primary);
    border-radius: 40px;
    padding: 8px 16px;
    color: #fff;
    margin: 0 8px;
    svg {
      width: 12px;
      height: 26px;
    }
    small {
      margin: 0;
      display: block;
      font-weight: 500;
      font-size: 70%;
    }
  }
  .booth-container {
    position: fixed;
    /* border: 1px solid red; */
    width: 70vw;
    max-width: 1240px;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -65%);
    opacity: 0;
    animation: appear 500ms 500ms forwards;

    .booth-img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
    .hot-actions {
      background: var(--gold);
      border-radius: 50%;
      border: none;
      padding: 0;
      box-shadow: 0px 5px 14px -4px var(--secondary);
      transition: all 300ms ease;
      color: #000;

      img,
      svg {
        transition: all 300ms ease;
        width: 100%;
        height: 80%;
      }
      small {
        transition: all 300ms ease;
        font-size: 1px;
        opacity: 0;
        color: #000;
      }
      &:hover {
        small {
          font-size: 14px;
          opacity: 1;
        }
        img,
        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
`
const HotButton = styled.button`
  position: absolute;
  animation: heartbeat 7s infinite;
  width: 100px;
  height: 100px;
  ${(props) => props.css}
`
export const viewBooth = {
  title: 'Growth Centre',
  content:
    'Established in 2012, the IMFeD For Growth program collaborates closely with healthcare professionals to provide them with the tools and support needed to assess and monitor every child\'s growth, intervening when necessary. IMFeD also conducts campaigns to educate parents on childhood stunting and encourages regular growth check-ups for their children' +
    '<br><br>' +
    'For downloadable materials and e-learning resources, please visit IMFeD website at <a href="https://imfed.my/" target="_blank">https://imfed.my</a><br/><br/>' +
    'For any inquiries, please contact the IMFeD Secretariat at <a href="mailto:secretariat@imfed.my">secretariat@imfed.my</a>',
  buttonPosition: {
    info: `
      left: 30%;
    `,
    tv: `
      left: 64%;
      top: 36%;
    `,
    pdf: `
    left: 45.8%;
    top: 36%;
    `,
    chat: `
    left: 10%;
    top: 67%;
    `
  },
  boothVideos: [
    {
      fileName: 'IMFeD Growth 2024',
      url: `https://player.vimeo.com/video/996568776?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`,
    },
  ],
  documents: [
    {
      fileName: 'IMFeD Website e-Learning Poster.pdf',
      url: '/pdf/IMFeD Website e-Learning Poster.pdf',
    },
    {
      fileName: 'Prevalence of Undernutrition and Associated Factors in Young Children in Malaysia.pdf',
      url: '/pdf/Prevalence of Undernutrition and Associated Factors in Young Children in Malaysia.pdf',
    },
    // {
    //   fileName: 'brochure2.pdf',
    //   url: '/pdf/brochure2.pdf',
    // },
  ],
  chat: 'mailto:secretariat@imfed.my',
}

export default function BoothPage({ currentUser }) {
  const history = useHistory()
  const [showBtn, setShowBtn] = useState(false)
  // const dispatch = useDispatch()
  // const { boothId } = useParams()
  // const { viewBooth, booths } = useSelector((state) => state.booth)

  const [iframeOpen, setIframeOpen] = useState(false)
  // const [posterOpen, setPosterOpen] = useState(false)
  const [downloadOpen, setDownloadOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)
  const [playVideo, setPlayVideo] = useState({})
  const [documentSelected, setDocumentSelected] = useState(null)
  const [infoOpen, setInfoOpen] = useState(false)

  // useEffect(() => {
  //   dispatch(getBooth(boothId))
  // }, [boothId, dispatch])

  // useEffect(() => {
  //   if (viewBooth && viewBooth.id == boothId) {
  //     dispatch(getBooths(viewBooth.exhibition_hall.id))
  //     Amplitude.visitBooth(viewBooth.title)
  //   }
  // }, [boothId, currentUser.id, dispatch, viewBooth])

  const handleModal = (video) => {
    Amplitude.watchBoothVideo(video.filename)
    setPlayVideo(video)
    setIframeOpen(true)
    // setVideoOpen(false)
  }

  const handleDownload = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'pdf')
    setDownloadOpen(!downloadOpen)
  }
  const handleOpenDocument = (file) => {
    setDocumentSelected(file)
    // setDownloadOpen(!downloadOpen)
  }

  const handleVideoModal = () => {
    // Amplitude.clickHotButtons(viewBooth.title, 'video')
    // setVideoOpen(!videoOpen)
    const video = viewBooth.boothVideos[0]
    Amplitude.watchBoothVideo(video.filename)
    setPlayVideo(video)
    setIframeOpen(true)
  }

  const handleChat = () => {
      Amplitude.clickHotButtons(viewBooth.title, 'chat');
      window.location.href = 'mailto:secretariat@imfed.my';
  };

  // const handlePoster = () => {
  //   Amplitude.clickHotButtons(viewBooth.title, 'wallpaper')
  //   setPosterOpen(!posterOpen)
  // }

  const handleInfo = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'info')
    setInfoOpen(!infoOpen)
  }

  // const navigatePrev = () => {
  //   setShowBtn(false)
  //   const navigateTo = booths.filter(booth=>booth.order===viewBooth.order-1)[0].id
  //   history.push(`/booths/${navigateTo}`)
  // }
  // const navigateNext = () => {
  //   setShowBtn(false)
  //   const navigateTo = booths.filter(booth=>booth.order===viewBooth.order+1)[0].id
  //   history.push(`/booths/${navigateTo}`)
  // }
  return (
    <Page>
      <PageWithBg bgImg={desktopAssets.boothBg}>
        {!showBtn && <Loader fullPage loading />}
        <LazyLoadComponent>
          <div className="booth-container">
            <LazyLoadImage
              effect="black-and-white"
              afterLoad={() => setShowBtn(true)}
              className="booth-img"
              src={desktopAssets.booth}
              width="100%"
              alt="booth image"
            />
            {showBtn && (
              <>
                <HotButton css={viewBooth.buttonPosition.tv} className="hot-actions" onClick={handleVideoModal}>
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <polygon points="10 8 16 12 10 16 10 8"></polygon>
                  </svg>
                  <small>
                    Video
                    <br />
                    Gallery
                  </small>
                </HotButton>

                <HotButton css={viewBooth.buttonPosition.info} className="hot-actions" onClick={handleInfo}>
                  <svg width="37.098" height="37.098" viewBox="0 0 37.098 37.098">
                    <g transform="translate(-10.397 -16.423)">
                      <g transform="translate(10.397 16.423)" fill="none" stroke="currentColor" strokeWidth="3">
                        <ellipse cx="18.549" cy="18.549" rx="18.549" ry="18.549" stroke="none" />
                        <ellipse cx="18.549" cy="18.549" rx="17.049" ry="17.049" fill="none" />
                      </g>
                      <text
                        transform="translate(28.946 43.472)"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="0.5"
                        fontSize="24"
                        fontFamily="Montserrat-Regular, Montserrat"
                      >
                        <tspan x="-3.228" y="0">
                          i
                        </tspan>
                      </text>
                    </g>
                  </svg>
                  <small>
                    Booth
                    <br />
                    Info
                  </small>
                </HotButton>

                {viewBooth.documents.length > 0 && (
                  <HotButton css={viewBooth.buttonPosition.pdf} className="hot-actions" onClick={handleDownload}>
                    <svg width="35.678" height="35.678" viewBox="0 0 35.678 35.678">
                      <path d="M0,0H35.678V35.678H0Z" fill="none" />
                      <path
                        d="M28.758,2H10.919A2.982,2.982,0,0,0,7.946,4.973V22.812a2.982,2.982,0,0,0,2.973,2.973H28.758a2.982,2.982,0,0,0,2.973-2.973V4.973A2.982,2.982,0,0,0,28.758,2ZM16.123,13.149a2.227,2.227,0,0,1-2.23,2.23H12.406v1.858a1.115,1.115,0,1,1-2.23,0V10.92a1.491,1.491,0,0,1,1.487-1.487h2.23a2.227,2.227,0,0,1,2.23,2.23Zm7.433,2.973a2.227,2.227,0,0,1-2.23,2.23H18.352a.736.736,0,0,1-.743-.743V10.176a.736.736,0,0,1,.743-.743h2.973a2.227,2.227,0,0,1,2.23,2.23ZM29.5,10.548a1.123,1.123,0,0,1-1.115,1.115H27.272v1.487h1.115a1.115,1.115,0,0,1,0,2.23H27.272v1.858a1.115,1.115,0,1,1-2.23,0V10.92a1.491,1.491,0,0,1,1.487-1.487h1.858A1.123,1.123,0,0,1,29.5,10.548Zm-17.1,2.6h1.487V11.663H12.406Zm-8.919-5.2A1.491,1.491,0,0,0,2,9.433V28.759a2.982,2.982,0,0,0,2.973,2.973H24.3a1.487,1.487,0,0,0,0-2.973H6.46a1.491,1.491,0,0,1-1.487-1.487V9.433A1.491,1.491,0,0,0,3.487,7.946Zm16.352,8.176h1.487v-4.46H19.839Z"
                        transform="translate(0.973 0.973)"
                        fill="currentColor"
                      />
                    </svg>
                    <small>
                      PDF
                      <br />
                      Gallery
                    </small>
                  </HotButton>
                )}
                {viewBooth.chat && (
                  <HotButton css={viewBooth.buttonPosition.chat} className="hot-actions" onClick={handleChat}>
                    {/* <svg width="20" height="20" viewBox="0 0 45.448 45.448">
                        <path d="M0,0H45.448V45.448H0Z" fill="none"/>
                        <path d="M37.086,33.3V6.787A3.8,3.8,0,0,0,33.3,3H6.787A3.8,3.8,0,0,0,3,6.787V33.3a3.8,3.8,0,0,0,3.787,3.787H33.3A3.8,3.8,0,0,0,37.086,33.3ZM14.173,23.793l3.977,4.791,5.87-7.556a.951.951,0,0,1,1.515.019l6.647,8.862a.946.946,0,0,1-.757,1.515H8.719A.943.943,0,0,1,7.98,29.89L12.7,23.83A.934.934,0,0,1,14.173,23.793Z" transform="translate(2.681 2.681)" fill="#f8c605"/>
                      </svg> */}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z"
                      />
                    </svg>

                    <small>Chat</small>
                  </HotButton>
                )}
              </>
            )}
          </div>
          <div id="button-container">
            <button className="hot-buttons back-hall" onClick={() => history.push(routes.lobby)}>
              <svg width="19" height="17.041" viewBox="0 0 19 17.041">
                <path
                  d="M14.25,17.042H4.75a2.378,2.378,0,0,1-2.375-2.375V6.578l-1.118.813a.786.786,0,0,1-.465.152A.792.792,0,0,1,.326,6.11L8.1.455a2.376,2.376,0,0,1,2.794,0L18.673,6.11a.791.791,0,0,1,.175,1.106.8.8,0,0,1-.641.326.783.783,0,0,1-.465-.152l-1.118-.813v8.088A2.378,2.378,0,0,1,14.25,17.042ZM8.708,8.334h1.583a2.377,2.377,0,0,1,2.375,2.374v4.75H14.25a.793.793,0,0,0,.792-.792V5.427L9.966,1.734a.793.793,0,0,0-.932,0L3.958,5.427v9.239a.793.793,0,0,0,.792.792H6.333v-4.75A2.377,2.377,0,0,1,8.708,8.334Zm0,1.583a.792.792,0,0,0-.792.791v4.75h3.167v-4.75a.792.792,0,0,0-.792-.791Z"
                  transform="translate(0 0)"
                  fill="currentColor"
                />
              </svg>
              <small className="extra-small">Back to Lobby</small>
            </button>
            {/* {viewBooth.order !== 0 && (
                <button className="hot-buttons" onClick={navigatePrev}>
                  <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839">
                    <g transform="translate(17.301 26.839) rotate(180)">
                      <path d="M.459,14.462,13.147,26.407a1.637,1.637,0,0,0,2.216,0l1.48-1.393a1.415,1.415,0,0,0,0-2.084L6.79,13.419,16.845,3.909a1.415,1.415,0,0,0,0-2.084L15.363.432a1.637,1.637,0,0,0-2.216,0L.459,12.376A1.415,1.415,0,0,0,.459,14.462Z" transform="translate(17.301 26.839) rotate(180)" fill="#fff"/>
                    </g>
                  </svg>
                  <small>Previous Booth</small>
                </button>
              )}
              {viewBooth.order !== booths.length-1 && (
                <button className="hot-buttons" onClick={navigateNext}>
                  <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839">
                    <path d="M.459,12.376,13.147.432a1.637,1.637,0,0,1,2.216,0l1.48,1.393a1.415,1.415,0,0,1,0,2.084L6.79,13.419,16.845,22.93a1.415,1.415,0,0,1,0,2.084l-1.48,1.393a1.637,1.637,0,0,1-2.216,0L.459,14.462A1.415,1.415,0,0,1,.459,12.376Z" transform="translate(17.301 26.839) rotate(180)" fill="#fff"/>
                  </svg>
                  <small>Next Booth</small>
                </button>
              )} */}
          </div>

          {/* <VideoModal videos={viewBooth.boothVideos} setVideoOpen={setVideoOpen} videoOpen={videoOpen} handleModal={handleModal} /> */}
          {iframeOpen && <IframeModal iframeOpen={iframeOpen} setIframeOpen={setIframeOpen} iframe={playVideo} />}
          <DownloadModal setDocumentSelected={handleOpenDocument} files={viewBooth.documents} setDownloadOpen={setDownloadOpen} downloadOpen={downloadOpen} />
          {documentSelected && <DocumentViewer file={documentSelected} setDocumentSelected={setDocumentSelected} />}
          {/* <PosterModal images={viewBooth.wallpaper_gallery} posterOpen={posterOpen} setPosterOpen={setPosterOpen} />*/}
          <InfoModal booth={viewBooth} infoOpen={infoOpen} setInfoOpen={setInfoOpen} />
        </LazyLoadComponent>
      </PageWithBg>
    </Page>
  )
}
