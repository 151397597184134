import React, { Suspense } from 'react'
import Media from 'react-media'
import { Loader } from 'src/components/shared/Loader'
import { SIZES } from 'src/styles/theme'
import MobileAuditorium from 'src/mobileOnlyPages/MobileAuditorium'
import AuditoriumPage from 'src/webOnlyPages/AuditoriumPage'
import { useAuditoriumController } from 'src/utils/useAuditoriumController'

export default function AuditoriumContainer() {
  useAuditoriumController()
  return (
    <Loader>
      <Suspense fallback={<div>Loading...</div>}>
        <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
          {(matches) =>
            matches ? 
              <MobileAuditorium /> 
              : 
              <AuditoriumPage  />
          }
        </Media>
      </Suspense>
    </Loader>
  )
}
