import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    background: transparent;
    border: none;
  }
  .wrapper {
    position: relative;
    min-height: 600px;
    height: 100%;
    overflow: hidden;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      height: 100%;
      color: #fff;
    }
  }
  .modal-header {
    padding: 8px;
    border: none;
    background: rgba(0, 0, 0, 0);

    button.close {
      opacity: 1;
      padding: 4px auto !important;
      z-index: 100;
      position: absolute;
      top: -3%;
      right: -3%;
      span {
        font-weight: 400;
        font-size: 48px;
        color: #f8c605;
        text-shadow: none;
      }
    }
  }
`

export default function ComingSoon({ showModal, hideModal }) {
  return (
    <StyledModal show={showModal} onHide={hideModal} size="xl" aria-labelledby="Coming Soon" centered>
      <Modal.Header closeButton />

      <Modal.Body>
        <div className="wrapper">
          <div className="content">
            <h4>Coming Soon...</h4>
          </div>
        </div>
      </Modal.Body>
    </StyledModal>
  )
}
