import React from 'react'
import styled from 'styled-components'
import { SIZES } from '../styles/theme'
import { useObject } from 'react-firebase-hooks/database'
import { NoticeDb } from '../firebaseConfig'

const Wrapper = styled.div`
  marquee {
    ${SIZES.noticeBar}
    position: fixed;
    left: 0;
    right: 0;
    background: var(--primary);
    z-index: 300;
    color: white;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 2px 0;

    span {
      margin: 0 0.8em;
    }
  }
`

export default function NoticeBar() {
  const [snapshot, loading, error] = useObject(NoticeDb)

  if (loading || error) {
    return <></>
  }

  return (
    <Wrapper>
      <marquee scrollamount={10}>
        <span className="font-weight-bold">{snapshot.val()}</span>
      </marquee>
    </Wrapper>
  )
}
