import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  color: #505c7c;
  .modal-content {
    padding: 0 8px;
    border: none;
    border-radius: 20px;
  }
  .modal-header {
    border: none;
  }
  .close {
    border: none;
    font-size: 24px;
    background: transparent;
  }
  .file-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    p {
      /* word-break: break-all; */
    }
    button {
      background: transparent;
      border: none;
      color: #FFAD2C;
    }
  }
`

export default function DownloadModal({ files, downloadOpen, setDownloadOpen, setDocumentSelected }) {
  return (
    <StyledModal
      show={downloadOpen}
      onHide={()=>setDownloadOpen(false)}
      aria-labelledby="download-modal"
      centered
    >
      <Modal.Header closeButton>
        <h6 id="download-modal">Select file(s) to preview</h6>
      </Modal.Header>
      <Modal.Body>
        {
          files.map(file=>(
            <div key={file.fileName} className="file-container" onClick={() => setDocumentSelected(file)}>
              <p>{file.fileName}</p>
              <button>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#f8c605" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="#f8c605" d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                  <polyline stroke="#f8c605" points="7 10 12 15 17 10"></polyline>
                  <line stroke="#f8c605" x1="12" y1="15" x2="12" y2="3"></line>
                </svg>
              </button>
            </div>
          ))
        }
      </Modal.Body>
    </StyledModal>
  )
}
