import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Document, Page, pdfjs } from 'react-pdf'
import { MOBILE_THRESHOLD, SIZES } from 'src/styles/theme'
import Amplitude from 'src/utils/amplitudeHelper'
import { useWindowSize } from 'src/utils/useWindowSize'
import styled from 'styled-components'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const SharedModal = styled(Modal)`
  left: ${(props) => (props.open ? '0' : '100%')};
  transition: 1s !important;
  /* &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  
  scrollbar-width: none;  */
  scrollbar-width: thin;
  .modal-dialog {
    width: 100%;
    max-width: 75%;
    margin: 0 !important;
    margin-left: auto !important;
    border-radius: 0;
  }
  .modal-content {
    border-radius: 0;
    width: 100%;
    min-height: 100vh;
    max-width: 100%;
    background: rgba(0, 0, 0, 0.55);
  }
  .react-pdf__Page__canvas {
    width: 100%;
    max-width: 100% !important;
  }
  .react-pdf__Page__textContent {
    display: none;
  }

  .modal-header {
    padding: 12px;
    border: none;
    display: block;
    background: rgba(0, 0, 0, 0.55);
    position: sticky;
    top: 0;
    z-index: 1000;
   
    button.close {
      opacity: 1;
      padding: 4px auto !important;
      z-index: 100;
      position: absolute;
      top: 5px;
      right: 20px;
      span {
        font-weight: 400;
        font-size: 34px;
        color: #fff;
        text-shadow: none;
      }
    }
  }
  p.file-description {
    padding: 8px auto;
    font-size: 16px;
    margin: 0;
    color: #fff;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
  }
  .modal-body {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 100%;
  }
  .buttons {
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
    z-index: 1000;
  }
  ${SIZES.mobile} {
    .modal-dialog {
      max-width: 100%;
    }
    .modal-header {
      display: flex;
      align-items: center;
      button.close {
        position: static;
        margin: 0;
        padding: 0;
      }
    }
    p.file-description {
      font-size: 14px;
      text-align: left;
    }
    .modal-content {
      background: rgba(0, 0, 0, 0.5);
    }
    .buttons {
      position: static;
    }
    .zoom-btn {
      margin: 0 2px;
      width: 30px;
      height: 30px;
    }
  }
`
const pdfFilePath = '/pdf/IMFed 2024 - Agenda.pdf'
export const VirtualAgendaModal = ({ showModal, hideModal }) => {
  const { windowWidth } = useWindowSize()
  
  const [pdfPages, setPdfPages] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [scale, setScale] = useState(1.0)
  const [open, setOpen] = useState(false)

  const pdfWidth = windowWidth <= MOBILE_THRESHOLD ? windowWidth : 744
  
  useEffect(() => {
    Amplitude.clickAgenda()
    setOpen(true)
  }, [])

  const handleZoomIn = () => {
    if (scale < 1.4) {
      setScale(scale + 0.1)
    }
  }

  const handleZoomOut = () => {
    if (scale > 0.5) {
      setScale(scale - 0.1)
    }
  }
  const handleDownload = () => {
    window.open(pdfFilePath, "_blank")
  }

  const toggleModal = () => {
    setOpen(false)
    setTimeout(hideModal, 900)
  }

  return (
    <SharedModal open={open} show={showModal} onHide={toggleModal} size="lg" aria-labelledby="gallery-pdf-modal" centered animation={false}>
      <Modal.Header closeButton>
        <p className="file-description">Programme</p>
        <div>
          <div className='buttons noselect'>
            <div className='zoom-btn' onClick={handleDownload}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="#000" d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline stroke="#000" points="7 10 12 15 17 10"></polyline>
                <line stroke="#000" x1="12" y1="15" x2="12" y2="3"></line>
              </svg>
            </div>
            <div className='zoom-btn' onClick={handleZoomIn}>+</div>
            <div className='zoom-btn' onClick={handleZoomOut}>-</div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Document
            file={pdfFilePath}
            loading={<p className="text-center text-white mt-3">Loading...</p>}
            onLoadSuccess={({ numPages }) => {
              setPdfPages(numPages)
            }}
          >
            <Page pageNumber={currentPage} scale={scale} width={pdfWidth} />
          </Document>
        </div>
      </Modal.Body>
    </SharedModal>
  )
}
